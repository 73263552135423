<template>
  <app-layout class="page__recorder-outside">
    <instructions />
    <record-shared
      :type="type"
      @send-voice="sendHandler"
      :disabled="loading"
      :min-duration="minDuration"
      ref="record"
      :voiceKey="voiceKey"
      :route="currentRoute"
    />
  </app-layout>
</template>

<script>
import AppLayout from "@/components/appLayout";
import RecordShared from "@/components/record";
import Instructions from "./components/instructions";
import RecordMixin from "@/mixins/record";
import RouteMixin from "@/mixins/routing";
import logVisiting from "@/mixins/logVisiting";
// import { registerStream } from "@/api"
// import { saveLog } from "@/modules/logger";
// import { removeStorageItem, setStorage } from "@/modules/localStorage";
// import { getStorage  } from "@/modules/localStorage";

import {
  ROUTE_AUDIO_CALIBRATION,
  ROUTE_SUSTAINED_AAH,
  RECORD_MIN_DURATION_CB,
} from "@/constants";

export default {
  name: "Calibration",
  components: { AppLayout, RecordShared, Instructions },
  mixins: [RecordMixin, RouteMixin, logVisiting],

  data: () => ({
    voiceKey: "audio1",
    currentRoute: ROUTE_AUDIO_CALIBRATION,
    nextRoute: ROUTE_SUSTAINED_AAH,
    type: "calibrate",
    minDuration: RECORD_MIN_DURATION_CB,
  }),

  beforeCreate() {
      // console.log("Session Registering");
      // removeStorageItem("session_metrics")
      // console.log(JSON.stringify(getStorage("popUpCount")))
      // removeStorageItem("popUpCount")
      // console.log(JSON.stringify(getStorage("popUpCount")))
      // if (this.$store.state.popUpCount != null) {
        // for (var key in this.$store.state.popUpCount) {
          // if (key in this.$store.state.popUpCount){
            // this.$store.state.popUpCount[key] = 0;
          // }
        // }
      // }
// 
      // var promise = registerStream(this.$store.state.user.session_id, this.$store.state.filling);
      // console.log("Registration promise:");
      // console.log(promise)
      // this.$store.state.StreamRegistrationPromise = promise;
      // promise.then((e) => {
        // console.log(e);
        // console.log("callback registered on calibration page, completed registration promise");
        // if (e.status == 200) {
          // this.$store.state.StreamerRegistered = true;
          // saveLog("stream-registration-success");
          // setStorage({ "stream-registration-status" : true });
        // }
      // }).catch((e) => {
      //   console.log(e);
        // saveLog("stream-registration-failure", {stack_trace: e});
        // setStorage({ "stream-registration-status" : false });
        // this.$store.state.StreamerRegistered = null;
      // })
  }


  
};
</script>
