<template>
  <div class="instruction">
    <h3 class="instruction__title">{{ title }}</h3>
    <vimeo
      v-if="isVideo"
      :url="video.url"
      :id="video.id"
      class="instruction__video"
      :disabled="recording"
    />
    <ul class="list" v-else>
      <li>
        Place your device flat on the table in front of you with the edge ~14 inches from your mouth.
      </li>
      <li>
        Remain silent during calibration
        <img
          src="@/assets/images/emoji.svg"
          alt="emoji"
          width="25"
          height="25"
          class="instruction__emoji"
        />
      </li>
      <li>Grant permission to access your microphone when requested</li>
      <li>
        Press <span class="instruction__success"><b>“Calibrate”</b></span> below
      </li>
    </ul>

    <div class="instruction__links">
      <a
        v-if="isVideo"
        @click.prevent="changeView('text')"
        href="#"
        class="instruction__link"
      >
        {{ titles["text"].link }}
      </a>
      <a
        v-else
        @click.prevent="changeView('video')"
        href="#"
        class="instruction__link"
      >
        {{ titles["video"].link }}
      </a>
    </div>
  </div>
</template>

<script>
import Instructions from "@/mixins/instructions";

export default {
  mixins: [Instructions],

  data: () => ({
    video: {
      url: "https://vimeo.com/423879558/d0c74b7695",
      id: "423879558",
    },
  }),
};
</script>
